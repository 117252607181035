import * as React from 'react';

import Page from '../components/Page';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Container from '../components/Container';

function NotFoundPage({ location }: { location: Location }) {
  return (
    <Page location={location} header={<Header search={false} />}>
      <Container size="xs">
        <div className="mt-40 pb-40 text-center">
          <h1 className="text-3xl">404 - Page Not Found</h1>
          <p className="text-base">
            Sorry, looks like you have stumbled across a page which does not exist.
          </p>
        </div>
      </Container>
      <Footer />
    </Page>
  );
}

export default NotFoundPage;
